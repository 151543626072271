@import "@/styles/variables";

.badge {
  width: fit-content;
  padding: $spacing-1 $spacing-3;
  border-radius: $border-radius-ellipse;
  font-size: $font-size-xxs;
  font-weight: 500;

  &__yellow {
    background-color: $color-yellow;
    color: $color-black;
  }

  &__green {
    background-color: $color-green-main;
    color: $color-white;
  }

  &__gray {
    background-color: $color-gray-dark;
    color: $color-white;
  }

  &__red {
    background-color: $color-red;
    color: $color-white;
  }

  &__orange {
    background-color: $color-orange;
    color: $color-white;
  }
}
