@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.listingUser {
  display: inline-flex;
  align-items: center;
  gap: $spacing-3;
  scale: 1;
  transition:
    transform 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transform-origin: left;
  animation: normal;
  padding-right: $spacing-3;
  border-radius: 20px;

  &:not(.listingUser__isNotActive) {
    cursor: pointer;

    &:hover {
      transform: scale(1.1, 1.1);
      background-color: $color-gray-light;
    }
  }

  &__avatar {
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
  }

  &__info {
    font-size: $font-size-s;
  }

  &__gender {
    color: $color-gray-dark;
    line-height: 0;
    font-size: $font-size-l;
  }

  &_verified {
    display: flex;
    align-items: center;
    gap: $spacing-4;
    margin-top: $spacing-3;
  }

  &_status {
    position: relative;
    height: 24px;
  }

  &_checked {
    position: absolute;
    bottom: -5px;
    right: -8px;
    background: #fff;
    color: $color-green-main;
    border-radius: 50%;
  }

  &_icon {
    line-height: 0;
    font-size: $font-size-xxl;
  }

  &_text {
    font-size: $font-size-s;
    color: $color-gray-dark;
  }

  &__isUserListings {
    .listingUser__info {
      font-size: $font-size-l;
      line-height: $font-size-l;
    }

    .listingUser_details {
      display: flex;
      gap: $spacing-3;
    }
  }
}
