@import "@/styles/variables.scss";
@import "@/styles/mixins.scss";

.listingVerifications {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: $spacing-4;
  column-gap: $spacing-3;

  &__withoutText {
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
  }

  &_item {
    display: flex;
    align-items: center;
    gap: $spacing-4;
  }

  &_status {
    position: relative;
    height: 24px;
  }

  &_checked {
    position: absolute;
    bottom: -5px;
    right: -8px;
    background: #fff;
    color: $color-green-main;
    border-radius: 50%;
  }

  &_icon {
    line-height: 0;
    font-size: $font-size-xxl;
  }

  &_text {
    font-size: $font-size-s;
    color: $color-gray-dark;
  }
}

.listingVerifications__isProfilePage {
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));

}

@include for-screen-from-568 {
  .listingVerifications__isProfilePage {
    grid-template-columns: repeat(2, 1fr);
  }
}
