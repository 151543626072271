@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.listingCard {
  width: 100%;
  height: 100%;
  border: 1px solid $color-gray;
  border-radius: $border-radius-base;
  overflow: hidden;


  &:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
  }

  &__withoutBorder {
    border: none;

    &:hover {
      box-shadow: none;
    }
  }
}
